// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, computed, input } from '@angular/core';
import { getMitigationColourOnScore } from 'src/app/questionnaire-response/questionnaire-results/saq.model';

@Component({
  selector: 'app-mitigation-score-indicator',
  template: `@if (hasValue()) {
      <section class="indicator">
        <span class="fsn-data-small-alt percentage">{{ (score() | number: '1.0-0') + '%' }}</span>
        <mat-progress-bar [value]="score()" [style.color]="progressColour()" />
      </section>
    } @else {
      -
    }`,
  styleUrls: ['./mitigation-score-indicator.component.scss'],
})
export class MitigationScoreIndicatorComponent {
  score = input<number>();
  hasValue = computed(() => {
    const scoreValue = this.score();
    return typeof scoreValue === 'number' && !isNaN(scoreValue);
  });
  progressColour = computed(() => getMitigationColourOnScore(this.score()));
}
