export const featureToggles = {
  allowDarkMode: false,
  allowDeleteRecords: false,
  componentZoo: false, // Design system; a place to test components in a controlled environment
  inlineDevParams: false, // Additional controls to play with components within the app
  newNavigationMenu: false, // turn on when menu is done
  'SAQ: Engagement (formerly Questionnaires)': false,
  'Reports: PDF Download': false,
  'DD: always show sector alert': false,
};

export type FeatureToggles = typeof featureToggles;
