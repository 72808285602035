import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, booleanAttribute, input } from '@angular/core';
import { MaterialModule } from 'src/app/ui/material.module';

@Component({
  selector: 'fsn-tag',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  icon = input(false, { transform: booleanAttribute });
  noBorder = input(false, { transform: booleanAttribute });
  size = input<'small' | 'large'>('small');
  @Input() customClass: string = ''; // this does not work with a signal input

  @HostBinding('class') protected get hostClasses() {
    return this.customClass;
  }
  @HostBinding('class.fsn-tag-no-border') protected get clsBorder() {
    return this.noBorder();
  }
  @HostBinding('class.fsn-tag-small') protected get clsSmall() {
    return this.size() === 'small';
  }
  @HostBinding('class.fsn-tag-large') protected get clsLarge() {
    return this.size() === 'large';
  }
}
